import React from "react";
import ContactCard from "./contactCard";

function Contacts() {
    const jeppeContact = {
        Name: "Jeppe Mikkelsen",
        Email: "octogroupdk@gmail.com",
        Title: "Tool Designer",
    }
    const steffanContact = {
        Name: "Steffan Schmidt",
        Email: "octogroupdk@gmail.com",
        Title: "Software Developer",
    }
    const contacts = [
        jeppeContact,
        steffanContact
    ]
    return <div className="contactContainer">
        {contacts.map((contact) => {
            return <ContactCard 
                key={contact.Name}
                contactInformation={contact}
            />
        })}
    </div>
}

export default Contacts