import React from "react";

function SocialMediaLink( {link} ) {
    return <span>
        <a href={link} target="_blank" rel="noreferrer">LinkedIn</a>
    </span>
}

function SocialMedia() {
    const octogroupLinkedInLink = "https://www.linkedin.com/company/octogroup-dk/"
    return <div className="socialMedia">
        <SocialMediaLink link={octogroupLinkedInLink} />
    </div>
}

export default SocialMedia