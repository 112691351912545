import React from "react";

function ContactCard( {contactInformation }) {
    return <div className="contactCard">
        <div className="contactCardInformation">
            <span>{contactInformation.Name}</span>
            <span>{contactInformation.Email}</span>
            <span>{contactInformation.Title}</span>
        </div>
    </div>
}

export default ContactCard