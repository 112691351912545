import React from "react";
import Contacts from "./components/contacts";
import SocialMedia from "./components/socialmedia"
import "./styling/splashpage.scss"

function SplashPage() {
    return <div>
        <div className="baseInformationBox">
            <div className="companyName">
                <span>OctoGroup</span>
                <span>Founded Q4, 2022</span>
            </div>
            <SocialMedia></SocialMedia>
        </div>
        <Contacts />
        <div className="baseUnderConstruction">
            <span></span>OctoGroup is under construction and is actively being worked on. Please do not hesitate to contact us if you have any inquiries.
        </div>
    </div>
} 



export default SplashPage